import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useScrollWatcher } from '@csc/dls/hooks'
import type { StandardCart, StandardLineItem } from '@/types/ShopFront/CheckoutStandards'
import { checkIsExtendItem } from '@/helpers/checkIsExtendItem'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import type { IconSize } from '@csc/dls/IconRenderer'

import { error } from '@/services/Log'
import { ADD_TO_CART_EVENT_NAME } from '../../events/addToCartEvent'

const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const Basket = dynamic(import('@csc/dls/Icons/Basket'))

const PreviewContent = dynamic(import('@/components/Sitewide/Navbar/CartPreview/PreviewContent/PreviewContent'))

export type MaybeEventStopper = {
  preventDefault?: () => unknown
  stopPropagation?: () => unknown
}
const functorMaybeEventStopper = (fn: () => unknown) => (e?: MaybeEventStopper) => {
  e?.preventDefault?.()
  e?.stopPropagation?.()
  fn()
}

declare const window: Window & typeof globalThis & {
  hj?: (...args) => void,
}

interface CartPreviewProps {
  cart: StandardCart | null,
  onUpdateCart: (cart: StandardCart | null) => void,
  pageType: string,
  iconSize?: IconSize
  color?: 'white' | 'black' | string
}

const getLineItems = (cart: StandardCart | null): StandardLineItem[] => (
  cart?.lineItems.map((item) => (
    checkIsExtendItem(item)
      ? {
        ...item,
        imageUrl: '/__statics/images/extend_logo.png',
        isWarranty: true,
        extendedSalePrice: item.extendedListPrice,
        options: [],
      }
      : item
  )) || []
)

const getCartItemsQUantity: (c: StandardCart | null) => number = (cart) => {
  const lineItems = getLineItems(cart)
  return lineItems.reduce((item: number, next) => item + next.quantity, 0)
}

const CartPreview: React.FC<CartPreviewProps> = ({
  cart,
  onUpdateCart,
  pageType,
  color = 'white',
  iconSize = 'md',
}) => {
  const [previewVisible, setPreviewVisible] = useState(false)

  const lineItems = getLineItems(cart)
  const quantity = getCartItemsQUantity(cart)

  const countPill = (typeof quantity === 'number' ? quantity : '...').toString()

  const { isAtTheTop } = useScrollWatcher({})
  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth >= 1024) {
      setPreviewVisible(previewVisible && isAtTheTop)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAtTheTop])

  const openPreview = functorMaybeEventStopper(() => {
    if (!previewVisible) {
      setPreviewVisible(true)
    }
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    document.getElementsByTagName('body')[0]
      .classList.add('has-cart')
  })

  const closePreview = functorMaybeEventStopper(() => {
    if (previewVisible) {
      setPreviewVisible(false)
    }
    document.getElementsByTagName('body')[0]
      .classList.remove('has-cart')
  })

  const removeCoupon = async () => {
    if (cart?.coupons?.[0]?.code) {
      const [
        { default: removeCouponFromCart },
        { getCart },
      ] = await allPromisesWithRetries(() => [
        import('@/services/Cart/removeCoupon'),
        import('@/services/Cart/getCart'),
      ])
      await removeCouponFromCart({ cartId: cart.id, couponCode: cart.coupons[0].code })
      onUpdateCart(await getCart())
    }
  }

  useEffect(() => {
    const addToCartEventHandler = () => {
      setPreviewVisible(true)
    }
    document.addEventListener(ADD_TO_CART_EVENT_NAME, addToCartEventHandler)
    return () => {
      document.removeEventListener(ADD_TO_CART_EVENT_NAME, addToCartEventHandler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, ADD_TO_CART_EVENT_NAME, setPreviewVisible])

  useEffect(() => {
    if (previewVisible && typeof window.hj === 'function') {
      try {
        window.hj('vpv', '/cart-preview')
        window.hj('tagRecording', ['Cart Preview opened'])
      } catch {
        error('Couldn\'t log to hotjar')
      }
    }
  }, [previewVisible])

  return (
    <li
      className="relative navUser-item navUser-item--cart"
      style={{
        maxHeight: '20px',
      }}
    >
      <button
        className="relative"
        data-cart-preview
        type="button"
        aria-label="cart-preview-dropdown"
        data-dropdown="cart-preview-dropdown"
        data-options="align:right"
        onClick={openPreview}
      >
        <IconRenderer
          IconComponent={Basket}
          size={iconSize}
          color={color}
          className="text-white lg:hidden"
        />
        <IconRenderer
          IconComponent={Basket}
          size="md"
          color={color}
          className="text-white hidden lg:block"
        />
        {quantity > 0 && (
          <Text size="sm" className="absolute bg-secondary h-5 w-5 text-center text-white rounded-full -right-1 -top-1 transform translate-x-1/2 -translate-y-1/2 cart-quantity">
            {countPill}
          </Text>
        )}
      </button>
      {pageType !== 'cart' && (
        <PreviewContent
          cart={cart}
          visible={previewVisible}
          lineItems={lineItems}
          onClose={closePreview}
          removeCoupon={removeCoupon}
          onUpdateCart={onUpdateCart}
        />
      )}
    </li>
  )
}

export default CartPreview
